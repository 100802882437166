<template>
    <section>
        <section class="d-flex flex-wrap poppins align-center">
            <v-btn text dense 
                @click="(prevRoute && prevRoute.name === 'Admin Course Enrollment') ? $router.back() : $router.push({ name: 'Admin Course Enrollment' , query: { search: '', page: 1, paginate: 10, role: '', status: '', created_at: '' }})"  class="mr-1 pa-0 f14">
                <v-icon small>
                    mdi-chevron-left
                </v-icon>
                BACK
            </v-btn>
            <span class="f14">/</span> <span class="fw600 primary--text f14 ml-2">{{ title  }}</span>
        </section>

        <section class="d-flex flex-wrap justify-space-between">
            <SearchBar 
                :placeholder="'Search student'" 
                :value.sync="search"
                @clear="clearSearch"
                @search="onSearch"
                class="mt-3 mb-5"/>
            <section class="d-flex flex-row ml-auto">
                <FilterMenu
                        :initialFilter="filter"
                        @resetFilters="clearFilters()"
                        @applyFilters="() => { page = 1, updateQuery() }"
                    >
                    <section>
                        <FormSelectObject
                            :label="'MODE OF PAYMENT'"
                            :items="mode_of_payment"
                            :value.sync="filter.mode_of_payment"
                            class="mb-3"
                        />

                        <FormSelectObject
                            :label="'TYPE OF PAYMENT'"
                            :items="filter.mode_of_payment === '' ? type_of_payment : (filter.mode_of_payment === 'online' ? online_type_of_payment : offline_type_of_payment)"
                            :value.sync="filter.type_of_payment"
                            :disabled="filter.mode_of_payment == 'online'"
                            class="mb-3"
                        />

                        <FormSelectObject
                            :label="'PAYMENT STATUS'"
                            :items="filterStatus"
                            :value.sync="filter.invoice_status"
                            class="mb-3"
                        />

                        <FormSelectObject
                            :label="'ENROLLMENT STATUS'"
                            :items="filterEnrollmentStatus"
                            :value.sync="filter.enrollment_status"
                            class="mb-3"
                        />

                        <FormTextFieldOptional
                            :label="'ENROLLED BY'"
                            :value.sync="filter.enrolled_by" />

                        <div class="mt-3">
                            <FormLabel class="mb-1" :label="'TRANSACTION DATE (YYYY-MM-DD)'" />
                        </div>
                        <label class="caption">FROM:</label>
                        <FormDatePicker 
                            :val="filter.date_from"
                            :max="maxDate"
                            class_="col-12"
                            @apply="(e) => {
                                filter.date_from = e
                                minDate = e
                            }"/>

                        <label class="caption">TO:</label>
                        <FormDatePicker 
                            :val="filter.date_to"
                            :min="minDate"
                            class_="col-12"
                            @apply="(e) => {
                                filter.date_to = e
                                maxDate = e
                            }"/>
                    </section>
                </FilterMenu>

                <ButtonExport 
                    @click="exportInvoices"
                    :loading="exportLoading"
                    :disabled="loading || enrollees.length === 0"
                    class="ma-1"/>
            </section>
        </section>

        <section class="mb-3 text-right">
            <FormLabel :label="`${totalCount} result/s`" v-if="totalCount !== 0"/>
            <FormLabel :label="`-`" v-else/>
        </section>
        
        <v-data-table
            :loading="loading"
            :headers="courseStudentsEnrollment"
            :items="enrollees"
            hide-default-footer
            :items-per-page.sync="itemsPerPage"
            class="text--center custom-border poppins f14 mt-2">
            <template v-slot:item.name="{ item }" >
                <div class="text-no-wrap">
                    {{ item.name }}
                </div>
            </template>
            <template v-slot:item.mode_of_payment="{ item }" >
                <span class="text-uppercase">
                    {{ item.mode_of_payment }}
                </span>
            </template>
            <template v-slot:item.date_enrolled="{ item }" >
                {{ $dateFormat.ymd(item.date_enrolled) }}
                <!-- , {{ $dateFormat.hhMM(item.date_enrolled) }} -->
            </template>
            <template v-slot:item.date_approved="{ item }" >
                {{ $dateFormat.ymd(item.date_approved) }}
            </template>
            <template v-slot:item.official_receipt="{ item }" >
                <v-btn 
                    v-if="item.mode_of_payment === 'online' && item.invoice_status !== 4" outlined 
                    color="primary" dense class="pa-2 f10" @click="requestOR(item)"
                    :loading="invoiceIDForOR === item.invoice_id && requestORLoading"
                    small> Request OR </v-btn>
                <a
                    v-if="item.mode_of_payment === 'offline' && item.official_receipt"
                    :href="item?.official_receipt?.url"
                    class="primary--text text-decoration-none f10"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View OR
                </a>
            </template>
            <template v-slot:item.check="{ item }">
                <v-btn v-if="item.mode_of_payment === 'online' && item.invoice_status !== 4" outlined color="primary" dense class="pa-2 f10" 
                    @click="checkPaymentStatus(item)"
                    :loading="checkLoading && invoiceIDToCheck === item.invoice_id"
                    small>Check</v-btn>
            </template>
            <template v-slot:item.payment_status_action="{ item }">
                <v-select
                    outlined
                    :items="actionItemsCanCancel"
                    item-text="text"
                    item-value="value"
                    width="100"
                    class="no-outline-custom-field poppins f14 ma-auto"
                    dense
                    :disabled="item.invoice_status === 1"
                    v-model="item.invoice_status"
                    v-on:change="updateStatus(item)"
                    :style="'width: min-content;'"
                    hide-details
                >
                    <template slot="selection"  slot-scope="data">
                        <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 1" style="background-color: #7BC14533">APPROVED</span>
                        <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 2" style="background-color: #BDBDBD33">PENDING</span>
                        <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 3" style="background-color: #F8835E33">DISQUALIFIED</span>
                        <span dense class="secondary-2--text px-2 border" v-if="item.invoice_status === 4">CANCELLED</span>
                    </template>
                </v-select>
            </template>
            <template v-slot:item.enrollment_status_action="{ item }">
                <div class="d-flex flex-row align-center">
                    <v-select
                        outlined
                        :items="actionItemsEnrollment"
                        item-text="text"
                        item-value="value"
                        width="100"
                        class="no-outline-custom-field poppins f14 ma-auto"
                        dense
                        v-model="item.enrollment_status"
                        v-on:change="updateEnrollmentStatus(item)"
                        :style="'width: min-content;'"
                        hide-details
                    >
                        <template slot="selection"  slot-scope="data">
                            <span dense class="secondary-2--text px-2 border" v-if="item.enrollment_status === 1" style="background-color: #7BC14533">ENROLLED</span>
                            <span dense class="secondary-2--text px-2 border text-no-wrap" v-if="item.enrollment_status === 2" style="background-color: #BDBDBD33">NOT ENROLLED</span>
                        </template>
                    </v-select>
                    <v-progress-circular
                        v-if="selectedInvoice === item.invoice_id && enrollmentLoading"
                        size="20"
                        indeterminate
                        color="primary"
                        ></v-progress-circular>
                </div>
            </template>
            <template v-slot:item.nomination_form="{ item }" >
                <a
                    v-if="item.nomination_form && item.nomination_form.url !== ''"
                    :href="item.nomination_form.url"
                    class="primary--text text-decoration-none f10"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
                <span v-else>-</span>
            </template>
            <template v-slot:item.type_of_payment="{ item }" >
                <v-select
                    v-if="item.invoice_status == 2 && item.mode_of_payment === 'offline'"
                    outlined
                    :items="offline_type_of_payment"
                    item-text="text"
                    item-value="value"
                    class="no-outline-custom-field poppins f14 ma-auto"
                    dense
                    width="150"
                    v-model="item.type_of_payment"
                    v-on:change="updateTypeOfPayment(item)"
                    :style="item.type_of_payment === '' ? 'width: min-content;' : ''"
                    hide-details
                >
                </v-select>
                <span v-else class="text-capitalize">
                    {{ item.type_of_payment }}
                </span>
            </template>
            <template v-slot:item.proof_of_payment="{ item }" >
                <a
                    v-if="item.proof_of_payment && item.proof_of_payment.url !== ''"
                    :href="item.proof_of_payment.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    <v-icon small class="mr-1" dense>mdi-open-in-new</v-icon>
                    View
                </a>
                <span v-else>-</span>
                <!-- <v-btn v-if="item.proof_of_payment" dense color="secondary-1" text class="f12" small @click="selected= item.proof_of_payment, dialog = true">
                    <v-icon small dense class="mr-1">
                        mdi-eye-outline
                    </v-icon>
                    View
                </v-btn>
                <span v-else>-</span> -->
            </template>
        </v-data-table>
        
        <FormPagination 
            :pageCount="pageCount" 
            :page="page"
            :paginate="paginationPaginate"
            @page="onPageChange" 
            @paginate="onPaginateChange"/>

        <DialogBox 
            :dialog="confirmationDialog"
            @close="() => { confirmationDialog = false, item.invoice_status = 2 }"
            :title="'Approve user ?'"
            :subtext="'This action cannot be undone.'"
            :btn1="'Cancel'"
            :btn2="'Approve'"
            @btn_1="() => {
                confirmationDialog = false, item.invoice_status = 2
            }"
            @btn_2="approve"
            :loading_btn2="statusLoading"
        />
    </section>
</template>

<script>
import { api } from '@/services/axios'
import { mapActions, mapState, mapMutations } from 'vuex';
import { courseStudentsEnrollment } from '../../../constants/tblheaders/enrollment';
import searchAndPaginateMixin from '@/mixins/searchAndPaginateMixin';

export default {
    mixins: [searchAndPaginateMixin],
    data: () => ({
        selectedInvoice: null,
        totalCount: 0,
        pageCount: 1,
        loading: false,
        enrollmentLoading: false,
        exportLoading: false,
        statusLoading: false,
        requestORLoading: false,
        checkLoading: false,
        courseStudentsEnrollment,
        confirmationDialog: false,
        actionItemsCanCancel: [
            { text: 'APPROVED', value: 1 },
            { text: 'PENDING', value: 2 },
            { text: 'DISQUALIFIED', value: 3 },
            { text: 'CANCELLED', value: 4 },
        ],
        actionItemsEnrollment: [
            { text: 'ENROLL', value: 1 },
            { text: 'UNENROLL', value: 2 },
        ],
        filterStatus: [
            { text: 'All', value: '' },
            { text: 'APPROVED', value: '1' },
            { text: 'PENDING', value: '2' },
            { text: 'DISQUALIFIED', value: '3' },
            { text: 'CANCELLED', value: '4' },
        ],
        filterEnrollmentStatus: [
            { text: 'All', value: '' },
            { text: 'ENROLLED', value: '1' },
            { text: 'NOT ENROLLED', value: '2' },
        ],
        item: null,
        selected: null,
        toApprove: false,
        dialog: false,
        title: '',
        mode_of_payment: [
            { text: 'All', value: '' },
            { text: 'OFFLINE', value: 'offline' },
            { text: 'ONLINE', value: 'online' },
        ],
        type_of_payment: [
            { text: 'All', value: '' },
            { text: 'CASH DEPOSIT', value: 'cash_deposit' },
            { text: 'CASH-CSC CASHIER', value: 'cash-csc_cashier' },
            { text: 'CHECK DEPOSIT', value: 'check_deposit' },
            { text: 'CHECK-CSC CASHIER', value: 'check-csc_cashier' },
            { text: 'DEBIT MEMO', value: 'debit_memo' },
            { text: 'LDDAP-ADA', value: 'lddap_pada' },
            { text: 'FUND TRANSFER', value: 'fund_transfer' },
            { text: 'LINKBIZ PORTAL', value: 'linkbiz_portal' },
        ],
        offline_type_of_payment: [
            { text: '-', value: '' },
            { text: 'CASH DEPOSIT', value: 'cash_deposit' },
            { text: 'CASH-CSC CASHIER', value: 'cash-csc_cashier' },
            { text: 'CHECK DEPOSIT', value: 'check_deposit' },
            { text: 'CHECK-CSC CASHIER', value: 'check-csc_cashier' },
            { text: 'DEBIT MEMO', value: 'debit_memo' },
            { text: 'LDDAP-ADA', value: 'lddap_pada' },
            { text: 'FUND TRANSFER', value: 'fund_transfer' },
        ],
        online_type_of_payment: [
            { text: '-', value: '' },
            { text: 'LINKBIZ PORTAL', value: 'linkbiz_portal' },
        ],
        invoiceIDToCheck: null,
        invoiceIDForOR: null,
        prevRoute: null,
        minDate: '',
        maxDate: ''

    }),
    methods: {
        ...mapActions('admin', ['getCourseEnrolleesAction', 'updateInvoiceStatus', 'requestORCSC', 'lbpStatusCheckAction', 'updateTypeOfPaymentAction', 'paymentExportAction', 'unenrollUserAction', 'enrollUserAction']),
        ...mapMutations(['alertMutation']),
        ...mapMutations('admin', ['enrolleesMutation']),


        clearFilters(){
            this.filter = {
                date_from: '',
                date_to: '',
                enrolled_by: '',
                mode_of_payment: '',
                type_of_payment: '',
                invoice_status: '',
                enrollment_status: ''
            }

            this.updateQuery()

            this.minDate = ''
            this.maxDate = ''
        },

        approve() {
            this.item.invoice_status = 2
            this.toApprove = true
            this.updateStatus(this.item)
        },

        errorMessage(){
            this.alertMutation({
                show: true,
                text: "Something went wrong",
                type: "error"
            })
        },

        getData(){
            if(!this.loading) {
                this.loading = true
                this.getCourseEnrolleesAction({
                    course_uuid: this.$route.params.course_uuid,
                    search: this.search,
                    page: this.page,
                    paginate: Number(this.paginate),
                    ...this.filter
                }).then(res => {
                    this.page = res.invoices.current_page
                    this.pageCount = res.invoices.last_page
                    this.paginate = String(res.invoices.per_page)
                    this.title = res.title
                    this.totalCount = res.invoices.total
                }).catch(() => {
                    this.errorMessage()
                }).finally(() => {
                    this.loading = false
                })

            }
        },

        updateTypeOfPayment(item) {
            this.loading = true
            this.updateTypeOfPaymentAction({invoice_id: item.invoice_id, type_of_payment: item.type_of_payment}).then(() => {
                this.getData()
            }).finally(() => {
                this.loading = false
            })
        },

        updateEnrollmentStatus(item) {
            this.selectedInvoice = item.invoice_id
            if(item.enrollment_status === 2) {

                this.enrollmentLoading = true
                this.unenrollUserAction({
                    id: item.enrollment_id,
                    user_id: item.user_id,
                    course_id: item.course_id
                }).then(() => {
                    this.enrollmentLoading = false
                    this.alertMutation({
                        show: true,
                        text: "Learner successfully unenrolled",
                        type: "success"
                    })
                    this.getData()
                }).catch(() => {
                    this.enrollmentLoading = false
                    this.alertMutation({
                        show: true,
                        text: "Something went wrong in unenrolling",
                        type: "error"
                    })
                })
            } else {
                this.enrollmentLoading = true
                this.enrollUserAction({
                    user_id: item.user_id,
                    course_id: [item.course_id]
                }).then(() => {
                    this.enrollmentLoading = false
                    this.alertMutation({
                        show: true,
                        text: "Learner successfully enrolled",
                        type: "success"
                    })
                    this.getData()
                }).catch(() => {
                    this.enrollmentLoading = false
                    this.alertMutation({
                        show: true,
                        text: "Something went wrong in enrolling",
                        type: "error"
                    })
                })

            }
        },

        updateStatus(item){
            this.item = item
            if(item.invoice_status === 1) {
                this.confirmationDialog = true
            } else {
                if(this.toApprove) {
                    this.statusLoading = true
                    this.updateInvoiceStatus({invoice_id: item.invoice_id, status: 1}).then(() => {
                        this.enrolleesMutation([])
                        this.getData()
                        this.alertMutation({
                            show: true,
                            text: "Successfully updated!",
                            type: "success"
                        })
                    }).catch(() => {
                        this.statusLoading = false
                        this.errorMessage()
                    }).finally(() => {
                        this.statusLoading = false
                        this.confirmationDialog = false
                    })
                } else {
                    this.statusLoading = true
                    this.updateInvoiceStatus({invoice_id: item.invoice_id, status: item.invoice_status}).then(() => {
                        this.enrolleesMutation([])
                        this.getData()
                        this.alertMutation({
                            show: true,
                            text: "Successfully updated!",
                            type: "success"
                        })
                        this.statusLoading = false
                    }).catch(() => {
                        this.statusLoading = false
                        this.errorMessage()
                    })
                }
            }
        },

        requestOR(item) {
            this.requestORLoading = true
            this.invoiceIDForOR = item.invoice_id
            this.requestORCSC({ 
                course_uuid: this.$route.params.course_uuid, 
                user_id: item.user_id,
                email: item.email,
                reference_id: item.reference_id,
            }).then((res) => {
                this.alertMutation({
                    show: true,
                    text: "Requested OR successfully generated!",
                    type: "success"
                })
                this.$exportToPDF(`${item.name}`, res)
            }).catch(err => {
                if (err.response && err.response.status === 404) {
                    this.alertMutation({
                        show: true,
                        text: 'Official Receipt not available.',
                        type: "error"
                    })
                } else {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong',
                        type: "error"
                    })
                }
            }).finally(() => {
                this.requestORLoading = false
                this.invoiceIDForOR = null
            })
        },

        checkPaymentStatus(item) {
            this.checkLoading = true
            this.invoiceIDToCheck = item.invoice_id
            this.lbpStatusCheckAction({ email: item.email, ref_id: item.reference_id, amount: item.amount}).then( _status => {
                this.getData()
            }).finally(() => {
                this.checkLoading = false
                this.invoiceIDToCheck = null
            })
        },

        exportInvoices(){
            this.exportLoading = true
            this.paymentExportAction({
                course_uuid: this.$route.params.course_uuid,
                search: this.search,
                ...this.filter
            }).then((res) => {
                this.$exportToCSV(`${this.title} - Transactions`, res)
            }).catch(e => {
                this.errorMessage()
            }).finally(() => {
                this.exportLoading = false
            })
        }
    },
    computed: {
        ...mapState('admin', {
            enrollees: (state) => state.enrollees
        }),

        itemsPerPage(){
            return Number(this.paginate)
        },

        paginationPaginate(){
            return String(this.paginate)
        }
    },
    mounted() {
        // 1 approved
        // 2 pending
        // 3 failed
        this.enrolleesMutation([])
        this.getData()
    },
    watch: {

        filter: {
            handler(val) {
                if(val.mode_of_payment === 'online') {
                    this.filter.type_of_payment = 'linkbiz_portal'
                }
            },
            deep: true
        },
    }
}
</script>
